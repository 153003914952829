'use strict';

var formValidation = require('../components/formValidation');
var createErrorNotification = require('../components/errorNotification');
var shippingHelpers = require('../checkout/shipping');
var dateOfBirth = require('../components/dateOfBirth');
var util = require('../components/util');

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        setTimeout(function () {
                            window.location.href = data.redirectUrl;
                        }, 1000);
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                    } else {
                        $('form.registration').trigger('login:register:success', data);
                        setTimeout(function () {
                            window.location.href = data.redirectUrl;
                        }, 1000);
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    changePhoneAreaFromCountry: function () {
        shippingHelpers.phoneArea();

        $('select[name$=_countryCode]').on('change', function () {
            var optionId = $(this).children(':selected').attr('id');
            var phoneArea = $('#phoneArea option[id=' + optionId + ']').val();

            $('.selected-item').val(phoneArea);
            $('.selected-item').text(phoneArea);
            $('.selected-item').prop('selected', true);
        });
    },

    validateInterestedIn: function () {
        $('.js-click-register').on('click', function () {
            util.interestedIn($('form.registration'));
        });
    },

    selectInterestedIn: function () {
        $('input[name$=customer_interestedIn]').val('');

        $('input[type=radio][name=interestedIn-registration]').on('change', function () {
            $('input[name$=customer_interestedIn]').val(this.value);
        });
    },

    enterDateOfBirth: function () {
        dateOfBirth.enterDateOfBirth();
    },

    validateDateOfBirth: function () {
        dateOfBirth.validateDateOfBirth();
    },

    dateOfBirthInputsCheck: function () {
        dateOfBirth.dateOfBirthInputsCheck();
    }
};
