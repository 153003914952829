'use strict';

var util = require('../components/util');

module.exports = {
    enterDateOfBirth: function () {
        $('.row-input input').on('keyup', function () {
            $(this).val($(this).val().replace(/[^0-9]/g, ''));

            if ($(this).val().length === parseInt($(this).attr('maxlength'), 10)) {
                $(this).closest('div').next().find('input')
                .focus();
            }
        });
    },

    validateDateOfBirth: function () {
        $('.js-click-register').on('click', function () {
            var dateOfBirthInputs = $('.date-of-birth-input input').filter(function () {
                return this.value.trim() === '';
            }).length;

            if (dateOfBirthInputs === 0 || dateOfBirthInputs === 3) {
                $('.date-of-birth-input').removeClass('is-invalid');
                $('#date-of-birth-error').css('display', 'none');
            } else if (dateOfBirthInputs < 3) {
                $('.date-of-birth-input').addClass('is-invalid');
                $('#date-of-birth-error').css('display', 'block');
            }
        });
    },

    dateOfBirthInputsCheck: function () {
        $('.row-input input').on('focusout', function () {
            var checkInputDay = util.checkValue($(this).val(), 31);
            var checkInputMonth = util.checkValue($(this).val(), 12);

            if ($(this).val().length < parseInt($(this).attr('maxlength'), 10)) {
                $(this).val('');
            }

            if ($(this).prop('id') === 'birthdayDay') {
                $(this).val(checkInputDay);

                if (checkInputDay === '1') {
                    $(this).val('');
                }
            }

            if ($(this).prop('id') === 'birthdayMonth') {
                $(this).val(checkInputMonth);

                if (checkInputMonth === '1') {
                    $(this).val('');
                }
            }
        });
    },

    editDateOfBirth: function () {
        var dateOfBirth = $('.edit-profile-form').data('dateOfBirth');
        var $day = $('input[name$=_birthdayDay]');
        var $month = $('input[name$=_birthdayMonth]');
        var $year = $('input[name$=_birthdayYear]');

        if (dateOfBirth) {
            var splitDateOfBirth = dateOfBirth.split(/[-/\s]/);

            if (splitDateOfBirth.length === 3
                && !(isNaN(splitDateOfBirth[0]) || isNaN(splitDateOfBirth[1]) || isNaN(splitDateOfBirth[2]))) {
                $day.val(splitDateOfBirth[0]);
                $month.val(splitDateOfBirth[1]);
                $year.val(splitDateOfBirth[2]);
            }
        }
    }
};
